import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import TimerIcon from "@mui/icons-material/Timer";
import Countdown from "react-countdown";
import moment from "moment-timezone";

import tempImg from "../../../../assets/temp-img.png";

const EventItemCard = ({ event, classes, isOnline, timezone }) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-12">
      <div className={classes.AuctionCards}>
        <div className={classes.AuctionCard}>
          <img
            className={classes.productImg}
            alt="product"
            src={
              event.eventItemImagesUrl.length > 0
                ? event.eventItemImagesUrl[0]
                : tempImg
            }
          />
          <Box className={classes.auctionContent} sx={{ paddingBottom: "5px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              Lot No. : {event.lotNumber}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              Quantity : {event.quantity}
            </Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              Start Price : {event.startPrice}
            </Typography>
            <Box>
              <span className={classes.name}>
                Auctioner Name {event.auctioneerName}
              </span>
            </Box>
            <Box>
              <span className={classes.location}>
                Location:- {event.event.city}, {event.event.province},{" "}
                {event.event.country}
              </span>
            </Box>
          </Box>
        </div>

        <div
          className={classes.status}
          style={{
            ...(event.event.eventFormat !== "ENGLISH_AUCTION" && {
              display: "none",
            }),
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ marginRight: "0em", fontSize: "16px" }}>
              Ends in
            </Typography>
            <Box>
              <div className={classes.time}>
                {isOnline ? (
                  <>
                    <TimerIcon />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        letterSpacing: "-1px",
                        margin: "auto",
                        color: "#000",
                      }}
                    >
                      {moment(event.endDateAndTime).tz(timezone).format() >
                      moment().tz(timezone).format() ? (
                        <Countdown
                          isPlaying={true}
                          date={moment(event.endDateAndTime)
                            .tz(timezone)
                            .format()}
                        />
                      ) : (
                        <p style={{ marginBottom: "0em" }}>
                          Deal has been Expired
                        </p>
                      )}
                    </Typography>
                  </>
                ) : (
                  <p>Please check your Internet!</p>
                )}
              </div>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EventItemCard;
