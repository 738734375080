import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";

import useStyles from "./auction.style";

const EventCard = ({ event }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className="col-lg-3 col-md-4 col-sm-12 mob-card">
      <div className={classes.AuctionCards}>
        <div className={classes.AuctionCard}>
          <p className={classes.firstText}>
            {event.status === "live" && (
              <span className={classes.liveButton}>Live Now</span>
            )}
            {event.status === "ended" && (
              <span className={classes.ended}>Ended</span>
            )}
            {event.status === "upcoming" && (
              <span className={classes.Upcoming}>Upcoming</span>
            )}
          </p>
          <img
            className={classes.productImg}
            alt="product"
            src={event.eventImageUrl}
            onClick={() => {
              // navigate(routes.auction, {
              //   state: { eventId: event.eventId },
              // });
              navigate(`auctions/${event.eventId}`);
            }}
          />
          <Box className={classes.auctionContent}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                height: "22px",
                overflow: "hidden",
                textOverflow: "ellispsis",
              }}
            >
              Event Name : {event.auctionTitle}
            </Typography>
            <Typography
              className={classes.fixedCard}
              sx={{ fontSize: "14px", fontWeight: "500" }}
            >
              Description : {event.description}
            </Typography>
            <Typography
              className={classes.fixedCard}
              sx={{ fontSize: "14px", fontWeight: "500" }}
            >
              Reference number : {event.referenceNumber}
            </Typography>
            <Box>
              <span className={classes.name}>
                Auctioner Name {event.auctioneerName}
              </span>
            </Box>
            <Box className={classes.loactionDetail}>
              <span className={classes.location}>
                Location:- {event.streetOne}, {event.streetTwo}, {event.city},{" "}
                {event.province}, {event.postalCode}, {event.country}...
              </span>
            </Box>
            <Box>
              <span className={classes.lots}>{event.totalLots} Lots</span>
            </Box>
          </Box>
        </div>
        {/* <div className={classes.status}>
                    <Typography>Ended</Typography>
                  </div> */}
      </div>
    </div>
  );
};

export default EventCard;
