import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import EventCard from "./EventCard";
import EventItemCard from "./EventItemCard";
import useStyles from "./auction.style";
import colorSchemes from "../../../../constants/colorSchemes";
import getEventsListAPI from "../../../../api/getEventsListAPI";
import homeItemAPI from "../../../../api/homeItemAPI";
import saveBidAPI from "../../../../api/saveBidAPI";
import {
  getTimeZone,
  localTimeZone,
  localSetTimeZone,
} from "../../../../common";
import "react-toastify/dist/ReactToastify.css";

function AuctionCard() {
  const classes = useStyles();
  const [changeIcon, setChangeIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [Events, updateEvents] = useState({ apiState: "loading", list: [] });
  const [EventItems, updateEventItems] = useState({
    apiState: "loading",
    list: [],
  });

  const timezone = getTimeZone() ? getTimeZone() : localTimeZone();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(8);
  const [pageLimit, setPageLimit] = React.useState(8);

  const getEventsList = async () => {
    try {
      const payload = { pageSize: pageSize, page: page };
      const { data } = await getEventsListAPI(payload);

      if (data) updateEvents({ apiState: "success", list: data.data });
      else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getEventsList();
    return () => {
      return false;
    };
  }, []);

  const getEventItemsList = async () => {
    try {
      const { data } = await homeItemAPI();

      if (data) {
        updateEventItems({ apiState: "success", list: data });
        if (isEmpty(getTimeZone())) {
          localSetTimeZone();
        }
      } else throw new Error(data);
    } catch (error) {
      console.clear();
      console.log(error);
    }
  };
  React.useEffect(() => {
    getEventItemsList();
    return () => {
      return false;
    };
  }, []);

  const [bidInfo, setbidInfo] = useState({
    eventId: "",
    eventItemId: "",
    amount: "",
  });

  const handleChange = (event) => {
    setbidInfo({ ...bidInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event, payload) => {
    try {
      event.preventDefault();
      if (isOnline) {
        bidInfo.amount = Number(bidInfo.amount);
        const payloadBid = {
          amount: Number(Number(bidInfo.amount).toFixed(2)),
        };
        const { data } = await saveBidAPI(
          payloadBid,
          payload.eventId,
          payload.eventItemId
        );

        if (data) {
          toast("Your Bid Submited!");
        }
      } else {
        toast("Please check Your Internet!");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.errorMessage);
        setIsLoading(false);
        if (
          error.response.data.errorMessage === "Missing authorization header."
        ) {
          toast("Please Login and then try!");
          setIsLoading(false);
        } else {
          toast(error.response.data.errorMessage);
          setIsLoading(false);
        }
      } else if (error.response.status === 500) {
        toast("Network Error!");
        setIsLoading(false);
      } else if (error.request) {
        toast("Something went wrong!");
        setIsLoading(false);
      } else {
        console.log("Error", error.message.code);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className={classes.auction}>
        <div className="container">
          <div className={classes.auctions}>
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "1em",
                fontSize: "22px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
                marginTop: "1em",
              }}
            >
              Auctions
            </Typography>
          </div>
          <div className="row">
            {Events.list.map((event, index) => (
              <EventCard event={event} key={index} />
            ))}
          </div>
        </div>
      </div>

      <div className={classes.auction}>
        <div className="container">
          <div className={classes.auctions}>
            <Typography
              className={classes.textHead}
              sx={{
                textAlign: "center",
                padding: "1em",
                fontSize: "22px",
                fontWeight: "600",
                color: colorSchemes.primaryColor,
              }}
            >
              Lots
            </Typography>
          </div>
          <div className="row">
            {EventItems &&
              EventItems.list.map((item, index) => (
                <EventItemCard
                  event={item}
                  key={index}
                  classes={classes}
                  timezone={timezone}
                  isOnline={isOnline}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AuctionCard;
